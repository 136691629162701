// BG Images
export const BG_COVER = `https://ik.imagekit.io/josantoso/kristofer-yohana/cover.jpg?updatedAt=1703745000673`;
export const BG_COUNTING_DOWN = `https://ik.imagekit.io/josantoso/kristofer-yohana/counting.jpg?updatedAt=1703745000584`;
export const BG_INSTAGRAM_FILTER = `https://user-images.githubusercontent.com/10141928/168461099-37de0e87-bf8b-4a3e-b825-d2970e79cdb7.jpg`;
export const BG_GUEST_INFO = `https://ik.imagekit.io/josantoso/kristofer-yohana/cover.jpg?updatedAt=1703745000673`;
export const BG_CONFIRMATION = BG_GUEST_INFO;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/josantoso/kristofer-yohana/dekstop.jpg?updatedAt=1703745000667`;

// Welcoming Image
export const IMG_PHOTO_1 = `https://ik.imagekit.io/josantoso/kristofer-yohana/w1.jpg?updatedAt=1703745005100`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/josantoso/kristofer-yohana/w2.jpg?updatedAt=1703745005179`;
export const IMG_PHOTO_3 = `https://ik.imagekit.io/josantoso/kristofer-yohana/w3.jpg?updatedAt=1703745005108`;

// Wedding Detail Image
export const IMG_AKAD = `https://ik.imagekit.io/josantoso/kristofer-yohana/pemberkatan.jpg?updatedAt=1703745003480`;
export const IMG_RECEPTION = `https://ik.imagekit.io/josantoso/kristofer-yohana/resepsi.jpg?updatedAt=1703745003584`;
export const IMG_DRESSCODE = `https://ik.imagekit.io/josantoso/kristofer-yohana/siraman.jpg?updatedAt=1703745003749`;

// Couple Image
export const IMG_COUPLE = `https://ik.imagekit.io/josantoso/kristofer-yohana/pengantin.jpg?updatedAt=1703745003533`;
export const IMG_LOVE_STORY = `https://ik.imagekit.io/josantoso/kristofer-yohana/pengantin.jpg?updatedAt=1703745003533`;
export const IMG_MAN = `https://user-images.githubusercontent.com/10141928/159520655-4e91aa6f-ba20-4f74-912a-62a0f361fc75.jpg`;
export const IMG_GIRL = `https://user-images.githubusercontent.com/10141928/159520628-f6c02869-e08e-4720-a4bd-095799e79cdf.jpg`;
export const IMG_WELCOMING_SECTION = `https://ik.imagekit.io/josantoso/kristofer-yohana/welcome.jpg?updatedAt=1703745003864`;
export const IMG_WISHES = `https://ik.imagekit.io/josantoso/kristofer-yohana/wishes.jpg?updatedAt=1703745000635`;

// Cover Gallery Image
export const IMG_GALLERY_1 = `https://ik.imagekit.io/josantoso/kristofer-yohana/g1.jpg?updatedAt=1703745000566`;
export const IMG_GALLERY_2 = `https://ik.imagekit.io/josantoso/kristofer-yohana/g2.jpg?updatedAt=1703745000664`;
export const IMG_GALLERY_COVER = `https://ik.imagekit.io/josantoso/kristofer-yohana/g3.jpg?updatedAt=1703745000639`;

// Logo Section
export const IMG_LOGO = `https://ik.imagekit.io/josantoso/kristofer-yohana/logo-new.png?updatedAt=1704505790458`;
export const IMG_LOGO_INVERT = `https://ik.imagekit.io/josantoso/kristofer-yohana/logo-invert-new.png?updatedAt=1704505790545`;

/**
 * Image Flower Assets
 * @important - IF YOU WANT TO OVERRIDE THIS ASSETS,
 * MAKE SURE YOU REPLACE WITH SAME RESOLUTION
 */
export const ASSETS_FLOWER_LEFT = `https://user-images.githubusercontent.com/10141928/167748673-62d2c7c6-9064-49f2-bcc0-9be00aaf510a.png`;

/**
 * Invitato Image Assets
 * @important - DON'T CHANGE THIS CODE
 */
export const LOGO_INVITATO = `https://user-images.githubusercontent.com/10141928/150662036-816aabf8-76f9-4342-bc47-3d7892362753.png`;
export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/10141928/152914092-4dfb40e5-9552-49dc-a2d4-72a345f08b06.png`,
  en: `https://user-images.githubusercontent.com/10141928/158618733-55b6141e-dc5a-4a6e-a65e-953cb09127dc.jpg`,
};
